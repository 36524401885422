<template>
    <section>
        <v-btn text @click="search = '', $emit('back')" class="mb-2">
            <v-icon small>
                mdi-arrow-left
            </v-icon>
            BACK
        </v-btn>
        <section class="d-flex flex-wrap justify-space-between mb-3">
            <SearchBar :placeholder="'Search user'" :value.sync="search" @search="$emit('search', search)"/>

            <!-- <v-text-field
            solo
            dense
            flat
            class="col-xl-3 col-lg-4 mb-3 custom-border general-custom-field f14 poppins fw500"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            v-model="search"
            /> -->
            
            <section class="d-flex flex-row align-center ml-auto">
                <v-chip class="fw400 f15" outlined>
                    <v-icon class="mr-2" color="primary">mdi-account-group-outline</v-icon>
                    <span class="fw600 mr-1 primary--text">{{ totalCount }}</span> users
                </v-chip>
                <v-btn dense outlined color="primary" class="ml-3" @click="exportTable" :loading="exportLoading">
                    Export List
                </v-btn>
            </section>
        </section>
        <v-expand-transition>
            <div>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    class="elevation-1 poppins"
                    :loading="loading"
                    hide-default-footer
                    :items-per-page.sync="paginate"
                    >
                    <template v-slot:item.status="{ item }">
                        <v-chip v-if="item.status === 'active'" small dense color="#7BC14533" class="success--text ma-auto">ACTIVE</v-chip>
                        <v-chip v-if="item.status === 'inactive'" small dense color="#BDBDBD33" class="secondary-2--text ma-auto">INACTIVE</v-chip>
                    </template>
                </v-data-table>
                <FormPagination 
                    :pageCount="pageCount" 
                    :page="page"
                    :paginate="paginate"
                    @page="(e) => $emit('page', e)" 
                    @paginate="(e) => $emit('paginate', e)"/>
            </div>
        </v-expand-transition>
    </section>
</template>

<script>
import * as XLSX from 'xlsx';
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
    props: {
        items: {
            type: Array,
            default: []
        },
        listName: {
            type: String,
            default: ''
        },
        selected: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        totalCount: {
            type: Number,
            default: 0
        },
        pageCount: {
            type: Number,
            default: 1
        },
        page: {
            type: Number,
            default: 1
        },
        paginate: {
            type: String,
            default: '10'
        },
        filter: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        headers: [ 
            { text: "First Name", value: "first_name"},
            { text: "Middle Name", value: "middle_name", align: 'center' },
            { text: "Last Name", value: "last_name", align: 'center' },
            { text: "Suffix", value: "suffix", align: 'center' },
            { text: "Email", value: "email", align: 'center' },
            { text: "Status", value: "status", align: 'center' },
        ],
        search: '',
        exportLoading: false,
    }),
    methods: {
        ...mapMutations(['alertMutation']),

        ...mapActions('admin', ['exportAdminUserAnalyticsAction']),

        exportTable() {
            this.exportLoading = true
            this.exportAdminUserAnalyticsAction({ filter: this.filter, value: this.value}).then(() => {
                this.exportLoading = false
            }).catch(() => {
                this.exportLoading = false
                this.alertMutation({
                    show: true,
                    text: 'Something went wrong',
                    type: "error"
                })
            })
        }
    },
    watch: {
        listName(val) {
            console.log(val)
        }
    }
}

</script>