<template>
    <v-expand-transition>
        <section>
            <section class="d-flex flex-wrap justify-space-between">
                <SearchBar v-if="selected === 'Total Users per Course'" :placeholder="'Search course'" :value.sync="search" @search="() => { page = 1, getContent() }"/>
                <!-- <v-text-field
                    solo
                    dense
                    flat
                    class="col-xl-3 col-lg-4 custom-border general-custom-field f14 poppins fw500 mb-3"
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Search"
                    v-model="search"
                /> -->
                <v-btn dense outlined color="primary" class="mb-3 poppins ml-auto" @click="exportTable" :loading="exportLoading">
                    Export List
                </v-btn>
            </section>
            <section v-if="totalCount !== 0" class="mb-3 text-right">
                <FormLabel :label="`${totalCount} result/s`"/>
            </section>
            <v-data-table
                :headers="headers"
                :items="courses"
                :loading="loading"
                class="elevation-1 custom-border border poppins"
                :page.sync="page"
                :items-per-page.sync="paginate"
                hide-default-footer
                >
                <template v-slot:item.title="{ item }">
                    <span v-if="item.hasOwnProperty('enrollments_count')">
                        <span @click="item.enrollments_count > 0 && $emit('viewList', { filter: selected, value: selected === 'Total Users per Course' ? item.id : item.title})" text class="poppins text-wrap pa-2 text-right ma-0 primary--text cursor-pointer" :style="'letter-spacing: normal; justify-content: flex-start;'">
                            {{ item.title }}
                        </span>
                    </span>
                    <span v-else-if="item.hasOwnProperty('users_count')">
                        <span @click="item.users_count > 0 && $emit('viewList', { filter: selected, value: selected === 'Total Users per Course' ? item.id : item.title })" text class="poppins text-wrap pa-2 text-right ma-0 primary--text cursor-pointer" :style="'letter-spacing: normal; justify-content: flex-start;'">
                            {{ item.title }}
                        </span>
                    </span>
                </template>
                <template v-slot:item.status="{ item }">
                    {{ item.status }}ED
                </template>
                <template v-slot:item.completed_course_count="{ item }">
                    {{ !item.hasOwnProperty('completed_course_count') ? '0' : item.completed_course_count }} <span class="secondary-2--text"> {{ (!item.hasOwnProperty('completed_course_count') || (item.completed_course_count === 0 || item.enrollments_count === 0)) ? '( 0 % )' : `( ${((item.completed_course_count / item.enrollments_count) * 100).toFixed(2)} % )` }} </span>
                </template>
                <template v-slot:item.course_complete_count="{ item }">
                    {{ !item.hasOwnProperty('course_complete_count') ? '0' : item.course_complete_count }} <span class="secondary-2--text"> {{ (!item.hasOwnProperty('course_complete_count') || (item.course_complete_count === 0 || item.users_count === 0)) ? '( 0 % )' : `( ${((item.course_complete_count / item.users_count) * 100).toFixed(2)} % )` }} </span>
                </template>
            </v-data-table>
            <FormPagination 
                :pageCount="pageCount" 
                :page="page"
                :paginate="paginate"
                @page="(e) => {page = e, getContent()}" 
                @paginate="(e) => {paginate = e, page = 1, getContent()}"/>
        </section>
    </v-expand-transition>
</template>

<script>
// import * as XLSX from 'xlsx';
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
    props: {
        selected: {
            type: String,
            default: ""
        },
    },
    data: () => ({
        headers: [ 
            { text: "COURSE", value: "title"},
            { text: "INSTRUCTOR", value: "instructor", align: 'center' },
            { text: "ENROLLED USERS", value: "enrollments_count", align: 'center' },
            { text: "STATUS", value: "status", align: 'center' },
        ],
        loading: false,
        exportLoading: false,
        totalCount: 0,
        pageCount: 1,
        paginate: '10',
        page: 1,
        search: '',
    }),
    methods: {
        ...mapMutations(['alertMutation']),

        ...mapActions('admin', ['getCoursesUserCount', 'exportAdminAnalyticsAction']),

        getContent(){
            this.loading = true
            this.getCoursesUserCount({ filter: this.selected, search: this.search, paginate: Number(this.paginate), page: this.page }).then(res => {

                this.page = res.current_page
                this.paginate = String(res.per_page)
                this.pageCount = res.last_page
                this.totalCount = res.total

                if(this.selected !== 'Total Users per Course') {
                    this.headers = [ 
                        { text: this.selected, value: "title"},
                        { text: "ENROLLED USERS", value: "users_count", align: 'center' },
                        { text: "COMPLETED", value: "course_complete_count", align: 'center' },
                    ]
                } else {
                    this.headers = [ 
                        { text: "COURSE", value: "title"},
                        { text: "INSTRUCTOR", value: "instructor", align: 'center' },
                        { text: "ENROLLED USERS", value: "enrollments_count", align: 'center' },
                        { text: "COMPLETED", value: "completed_course_count", align: 'center' },
                        { text: "STATUS", value: "status", align: 'center' },
                    ]
                }
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },

        exportTable() {
            this.exportLoading = true
            this.exportAdminAnalyticsAction({ filter: this.selected }).then(() => {
                this.exportLoading = false
            }).catch(() => {
                this.exportLoading = false
                this.alertMutation({
                    show: true,
                    text: 'Something went wrong',
                    type: "error"
                })
            })
            // let courses = []
            // this.courses.forEach(course => {
            //     if(this.selected !== 'Total Users per Course') {
            //         courses.push({
            //             'COURSE NAME': course.title ? course.title : 'Untitled',
            //             'ENROLLED USERS': course.users_count,
            //             'COMPLETED': course.course_complete_count
            //         })
            //     } else {
            //         courses.push({
            //             'COURSE NAME': course.title ? course.title : 'Untitled',
            //             'INSTRUCTOR': course.instructor,
            //             'ENROLLED USERS': course.enrollments_count,
            //             'COMPLETED': course.completed_course_count,
            //             'STATUS': course.status
            //         })
            //     }
            // })
            // const worksheet = XLSX.utils.json_to_sheet(courses)
            // const wb = XLSX.utils.book_new()
            // XLSX.utils.book_append_sheet(wb, worksheet, ``)
            // XLSX.writeFile(wb,`admin-analytics.xlsx`)
        }
    },
    created() {
        this.getContent()
    },
    computed: {
        ...mapState('admin', {
            // courses: (state) => state.courses
            courses (state) {
                state.courses.map(item => {
                    if(item.hasOwnProperty('uploader')) {
                        item.instructor = `${ item.uploader.first_name ? item.uploader.first_name : 'Instructor' } ${ item.uploader.middle_name ? this.$userFormat.middle_initial(item.uploader.middle_name) : '' } ${ item.uploader.last_name ? item.uploader.last_name : 'Account' } ${ item.uploader.suffix ? item.uploader.suffix : '' }`
                    }
                })

                return state.courses
            }
        })
    },
    watch: {
        selected() {
            this.search = ''
            this.getContent()
        },
    }
}
</script>